import { Sentry } from "./sentryInstrument";
import { handleSiteModal } from "./util/modalHelperFunctions";

const dialogs = document.querySelectorAll(".popity");
const popityContainer = document.getElementById("popity-container");
let isOpenDialogPresent;


// fetch URL Contents
async function fetchPopity() {
  const params = location.search ? location.search + "&ref=" + document.referrer : "?ref=" + document.referrer;
  try {
    const response = await fetch("/ads/popity.html" + params);
    if (response.ok) {
      return response.text();
    } else {
      console.log("There was an error: " + response.status);
	  return "";
    }
  } catch (error) {
    if (Sentry && Sentry.captureException) {
      Sentry.captureException(error);
    } else {
      console.error("Sentry is not initialized", error);
    }
    return "";
  }
}


// detect if any other dialog modals are open
function openDialogsPresent(popity) {
  isOpenDialogPresent = false;

  dialogs.forEach((dialog) => {
    if (dialog.open) {
      isOpenDialogPresent = true;
    } 
  });

  if (isOpenDialogPresent) {
    setTimeout(() => openDialogsPresent(popity), 3000);
  } else {
    handleSiteModal(popity, "open");
  }
}


document.addEventListener("DOMContentLoaded", () => {
  let modalOpened = false;

  if (popityContainer === null) {
    return;
  }

  fetchPopity().then(popityHTML => {
    popityContainer.innerHTML = popityHTML;

    const popities = document.querySelectorAll(".popity");
    popities.forEach((popity) => {
      const popityID = Number(popity.dataset.id);
      if (!modalOpened) {
  
        // trigger functionality
        const popityTrigger = popity.dataset.trigger;
        
        if (popityTrigger === "load") {
  
          handleSiteModal(popity, "open");
  
        } else if (popityTrigger === "3delay") {
  
          setTimeout(() => openDialogsPresent(popity), 3000);
  
        } else if (popityTrigger === "25scroll") {
  
          let modalScrollTriggered = false;
          window.addEventListener("scroll", () => {
            if (!modalScrollTriggered) {
              const scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
              if (scrollPercentage >= 25) {
                handleSiteModal(popity, "open");
                modalScrollTriggered = true;
              }
            }
          });
          
        }
        modalOpened = true; // prevents more than one popity modal from showing
      } 
    
    });

  });
     
});


